@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

:root {
    --primary-color: #269EFF;
    --light-gray: #F9F9F9;
    --secondary-color: #323B4F;
}

body {
    background-color: #F9F9F9 !important;
}

html, body, main, #root, .App {
    height: 100%;
    font-family: "Nunito Sans";
    color: #2A3446;
    font-size: 1rem !important;
}


h1 {
    margin-bottom: 0px !important;
    font-size: 2rem !important;
}

h2 {
    font-size: 1.7rem;
}

.line {
    height: 0.5px;
    background-color: #1b1e21;
}

.blueStripe {
    height: 5rem;
    background-color: var(--secondary-color);
}

.formHeading {
    font-size: 1.4rem;
    margin: 0;
    padding-right: 0.5rem;
}

.container {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, .15);
    padding: 2rem 1rem;
    margin: auto;
    margin-top: -5.01rem;
}

@media (min-width: 600px) {
    .container {
        max-width: 500px !important;
        margin-top: -3rem !important;
        border-radius: .375rem;
        padding: 2.5rem;
    }
}

frame {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
}

.shell {
    display: flex;
    flex-direction: column;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: column;
}

.registration {
    width: 6rem;
}

.registration:hover {
    height: 30px;
    box-shadow: 0 0 22px rgba(22, 22, 22, .4);
}

#logo {
    position: relative;
}

.topline {
    color: #00a2ff;
    font-weight: bold;
}

@media (max-width: 800px) {
    .registration {
        transition: box-shadow .3s;
        border-radius: 5px;
    }

    #logo img {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 40px;
        width: 98px;
    }

    .topline {
        position: relative;

    }
}

@media (min-width: 800px) {
    .registration {
        transition: box-shadow .3s;
        border-radius: 25px;
    }

    #logo img {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 80px;
        width: 197px;
    }


    .topline {
        position: relative;

    }
}

.form-control {
    border-color: #DFE2E6;
    border: 1px solid;
    border-radius: 0.25rem;
    font-size: 1rem !important;
}

.secondaryButton {
    background-color: white !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    font-size: 1rem !important;

}

.primaryButton {
    background-color: var(--primary-color) !important;
    color: white !important;
    font-size: 1rem !important;

}

.QR-field {
    width: 320px;
    height: 300px;
    border-radius: 10px;
}

.QR-field > div > div > div > div > div > span > button {
    background-color: white;
    border: 1px solid var(--primary-color);
    border-radius: 0.1875rem;
    color: var(--primary-color);
}

.QR-field > div > div > video {
    border-radius: .375rem !important;
}

.QR-field > div {
    border: none !important;
}

.grid {
    display: flex;
    flex-direction: column;
}

@media (min-width: 800px) {
    .grid {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}

.appContainer {
    all: unset;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: white;
    /*align-items: center;*/
    justify-content: center;
    /*border: 1px solid var(--primary-color);*/
    /*box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);*/
    padding: 0.75rem;
    margin: 0.5rem 1rem 0.5rem 0rem;
    border-radius: .375rem;
    background-color: var(--secondary-color);
    width: fit-content;
    min-width: 20ch;
}

.appContainer:hover {
    text-decoration: unset;
    color: white;
}

.text {
    font-size: 0.9rem;
    line-height: 1.1rem;
}