/* Base Layout */
body {
  font-family: 'Segoe UI', 'Helvetica Neue', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  margin: 0;
  padding: 20px;
}

.new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.input-group, .button-group {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
/* Primary Button */
.button-primary {
  background-color: #4A90E2; /* Blue */
  color: white;
}

.button-primary:hover {
  background-color: #357ABD; /* Darker blue */
}

/* Danger Button for critical actions like deletion */
.button-danger {
  background-color: #E25353; /* Red */
  color: white;
}

.button-danger:hover {
  background-color: #C04848; /* Darker red */
}


/* Popup styling */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%; /* Adjust width to better fit the content */
  max-width: 600px; /* Ensure it doesn't get too large on bigger screens */
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10; /* Ensure it's above everything else */
}

/* Enhanced input field styling */
input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Layout for form controls, using flexbox for alignment */
.form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  flex-basis: 30%; /* Adjust based on your preference */
  min-width: 120px; /* Ensures labels do not shrink too much on small screens */
}

.input {
  flex: 1; /* Takes up remaining space */
}

/* Button styles */
button {
  background-color: #4A90E2; /* Primary blue */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 8px 2px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #357ABD;
}

/* Specific styling for the cancel button if needed */
.button-cancel {
  background-color: #f44336; /* Red for cancel actions */
}

.button-cancel:hover {
  background-color: #d32f2f;
}

.input-error {
  border: 2px solid red;
  background-color: #ffe6e6;
}
.scan-button {
  position: relative;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.scan-button-text {
  position: relative;  /* Make sure the text is above the progress bar */
  z-index: 3;
}
.scan-button-success {
  background-color: green; /* Green for success */
}
.scan-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: green;
  z-index: 0;  /* Make sure the progress bar is behind the text */
  width: 0%;  /* Start with zero width */
  transition: width 0.3s ease;
}

/* Error state: when no value is returned */
.scan-button-error {
  background-color: red;
  z-index: 2;
}
.scan-button-error .scan-progress {
  width: 0% !important;  /* Hide progress bar when error is shown */
}


