.footer-bar {
    position: relative;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center !important;
    justify-items: center;
    padding-top: 3rem;

}

.footer-content {
    margin: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 600px) {
    .footer-content {
    }

}

.axxelia-div {
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1rem;
}