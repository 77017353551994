/* Basic styling for the main container */
.device-management-container {
    font-family: "Nunito Sans", sans-serif;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
}

.device-management-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* This will center the content vertically */
    height: 100%; /* This makes sure that the container is at least the height of the container */
    margin: 0 auto; /* This centers the container horizontally */
    max-width: 100%; /* Your existing max width */
    padding: 1rem; /* Your existing padding */
}

.actions button:not(:last-child) {
    margin-right: 1rem;
}

.bos {
    margin-bottom: 1rem; /* Adds space below every element with class1 */
}

.devices-table {
    margin-bottom: 2rem; /* Adds space below every element with class1 */
}

/* Styling for headers */
h1 {
    color: #323B4F;
    font-size: 24px;
    padding-bottom: 1rem;

}

/* Connection status styling */
.connection-status {
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

.connection-status.connected {
    color: green;
}

.connection-status.disconnected {
    color: red;
}

@media screen and (max-width: 600px) {
    /* Show the popup only on small screens */
    #allocatePopup.modal {
        display: block;
    }
}

/* For larger screens, hide the popup */
#allocatePopup.modal {
    display: none;
}


/* Table styling */
.devices-table {
    width: 100%;
    border-collapse: collapse;
}

.devices-table th, .devices-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.devices-table th {
    background-color: #f4f4f4;
}

.devices-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Button styling */
button {
    background-color: #4CAF50;
    color: white;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    border: none;
    cursor: pointer;
    border-radius: 0.25rem;
}

button:hover {
    background-color: #45a049;
}

/* Popup styling */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    padding-top: 60px;
}

.modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* Add more styles based on your UI needs */

/* Responsive design (example for smaller screens) */
@media screen and (max-width: 600px) {
    .device-management-container {
        padding: 5px;
    }

    h1 {
        font-size: 20px;
    }

    /* Adjust styles for smaller screens */
}
button:disabled {
    background-color: #c8c8c8; /* Grey background for disabled button */
    cursor: not-allowed; /* Cursor to indicate button is disabled */
    color: #6c757d; /* Text color for disabled state */
}

#allocatePopup {
    position: fixed;
    z-index: 1000; /* High z-index to ensure it's on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Darker background for the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

#allocatePopup .popupContent {
    background: white;
    padding: 30px; /* More padding for a spacious look */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Softer shadow */
    z-index: 1010; /* Ensuring the content is above the overlay */
    width: 450px; /* Fixed width for consistent sizing */
    position: relative; /* Position relative to the flexbox centering */
}

#allocatePopupWindow input {
    width: 100% !important; /* Full width of the popupContent */
    padding: 12px 20px; /* Comfortable padding inside the input */
    margin-bottom: 0px; /* Spacing between the input and the buttons */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 4px; /* Slightly rounded corners for the input field */
    font-size: 16px; /* Readable font size */
}

#allocatePopup buttonContainer {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px; /* Matching font size for buttons */
    font-weight: bold; /* Making the text a bit bolder */
    width: 100%;
}

#allocatePopupWindow buttonContainer.okButton {
    background-color: #4CAF50; /* The 'OK' button color */
    color: white;
}

#allocatePopupWindow .okButton:hover {
    background-color: #45a049; /* A shade darker for hover effect */
}

#allocatePopupWindow .cancelButton {
    background-color: #f44336; /* The 'Cancel' button color */
    color: white;
}

#allocatePopupWindow .cancelButton:hover {
    background-color: #8c8c8c; /* A shade darker for hover effect */
}

#allocatePopupWindow {
    min-width: 360px;
}

#addDevicePopup {
    position: fixed;
    z-index: 1000; /* High z-index to make sure it's on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#addDevicePopup .popupContent {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1010;
    width: 450px; /* Adjust width as needed */
    position: relative;
}


#addDevicePopup input {
    display: flex; /* Make inputs stack vertically */
    width: 100%; /* Account for padding */
    margin-bottom: 0px; /* Space between inputs and between input and button */
    padding: 10px 20px;
    margin-right: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
.input-with-icon {
    position: relative;
    display: flex;
    align-items: center;
}

.device-id-input {
    flex-grow: 1;
    margin-right: -2rem; /* Make room for the icon */
}

.device-id-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px; /* Space before the buttons */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffdd57; /* Bright background for the dropdown */
}

.device-id-select:focus {
    outline-color: #ffeea1; /* Lighter yellow for focus */
}
.qr-icon {
    position: absolute;
    right: 10px; /* Adjust based on your design */
    top :15px;

    cursor: pointer;
    height: 24px ;
    font-size: 24px;
}
#addDevicePopup button {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    line-height: 1.6;
}

#addDevicePopup button:hover {
    opacity: 0.8;
}

#addDevicePopup button.okButton {
    background-color: #4CAF50;
    color: white;
    justify-content: left;
}

#addDevicePopup button.cancelButton {
    background-color: #a5a5a5;
    color: white;
}

#devicePopup {
    min-width: 360px !important;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

#aboutpopup {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; /* To prevent content from touching the edges */
    box-sizing: border-box; /* To include padding in width and height */
}

#aboutpopup .popupContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1010;
    max-width: 600px; /* Adjust based on content */
    width: 90%; /* Responsive width */
    max-height: 80vh; /* To prevent overflow on small screens */
    overflow-y: auto; /* To add scroll to the content if it overflows */
    position: relative;
}

#aboutpopup table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px; /* Space between table and the next elements */
}

#aboutpopup th,
#aboutpopup td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd; /* For a light line between rows */
}

#aboutpopup th {
    background-color: #f2f2f2; /* A light background for headers */
    font-weight: bold;
}

.customize1 {
    margin-bottom: 15px; /* Space between input fields */
}

.customize1 label {
    margin-right: 10px;
}

.textzone {
    padding: 8px;
    margin: 5px 0; /* Space above and below the input */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 16px); /* Full width minus padding */
}

.closeButtonContainer {
    display: flex;
    justify-content: space-between;
    padding: 20; /* Optional: Adds some space around the button */
}

.cancelAndOk {
    display: flex;
    justify-content: space-between;
}
.cancelAndOk button:disabled {
    background-color: #cccccc; /* Grey out the button when disabled */
    color: #666666; /* Lighter text color for disabled state */
}


.cancelAndOk .buttonAdd {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.cancelAndOk button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
}

.cancelAndOk button:hover {
    opacity: 0.8; /* Subtle hover effect */
}


#returnPopup {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

#returnPopup .popupContent {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1010;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Make children width match the parent */
}

#returnPopup input,
#returnPopup textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Full width of the popupContent */
    box-sizing: border-box; /* Include padding in the width */
}

#returnPopup textarea {
    height: 100px; /* Fixed height for the textarea */
    resize: vertical; /* Allow vertical resizing */
}

#returnPopup button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 10px; /* Add some space above the button */
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
}

#returnPopup button:hover {
    background-color: #45a049; /* A shade darker for the hover effect */
}

.actions {
    position: relative; /* This allows absolute positioning relative to this container */
    /* Other styles for .actions container */
}

.selct-button, .deselct-button, .delete-button, .export-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.selct-button, .deselct-button {
    background-color: #323B4F; /* A green color, for example */
    color: white;
}

.selct-button:hover, .deselct-button:hover {
    background-color: #d5d5d5; /* A darker shade for hover effect */
}


.export-button {
    background-color: #e7e7e7; /* A neutral color for export */
    color: black;
}

.export-button:hover {
    background-color: #d5d5d5; /* A darker shade for hover effect */
}

.search-bar {
    padding-bottom: 1rem;
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.fixed-delete-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    bottom: 300px; /* Distance from the bottom of the viewport */
    right: 630px; /* Distance from the right side of the viewport */
    z-index: 100; /* Ensure it's above other content */
}

.delete-button {
    padding: 10px 20px;
    background-color: #f44336; /* Example styling, adjust as needed */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position:fixed;
    bottom: 160px;
}


.fixed-add-button-container {
    /* position: fixed; Or `absolute` depending on your layout needs */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;
    bottom: 400px; /* Distance from the bottom of the viewport */
    right: 615px; /* Distance from the right side of the viewport */
    z-index: 100; /* Ensure it's above other content */
}

.buttonAdd {
    padding: 10px 20px;
    background-color: #12c721; /* Example styling, adjust as needed */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
