.headerContainer {
    /*color: #2A3446;*/
    color: white;
    background-color: #323B4F;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 0.1);
    align-items: center;

}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    align-items: center;
    margin: auto;

}

@media (min-width: 600px) {
    .header {
        max-width: 500px;
        padding: 0.25rem 0;
    }
}

.headerTitle {
    font-family: "Nunito Sans";
    font-size: 2rem;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.backbutton {
    all: unset;
    color: white;
    font-size: 1rem;
    border: 1.75px solid white;
    border-radius: 0.1875rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    margin: 0.5rem;


}

.backbutton:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
    background-color: #4A90E2;
    border: 1.75px solid #4A90E2;

}

.buttonContainer {
    display: flex;
}

.image {
    margin-right: 1.5rem;
}

@media (min-width: 600px) {
    .headerTitle {
        font-size: 2.5rem;
    }

}
